import * as React from 'react'

export default function Loading() {
    return (
        <div
            className="inline-block h-8 w-8 animate-spin rounded-full border-4 border-solid border-current border-e-transparent align-[-5em] text-brown motion-reduce:animate-[spin_1.5s_linear_infinite]"
            role="status"
        ></div>
    )
}
