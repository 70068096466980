import { useState, useEffect } from 'react'
import course from '../../api/course/api-course'
import weightLoss from '../../assets/perte-de-poids.jpg'
import boxing from '../../assets/boxe.jpg'
import weightGain from '../../assets/prise-de-masse.jpg'
import bodyRecomposition from '../../assets/remise-en-forme.jpg'
import Loading from '../loading/loading'

export default function Services() {
    const [courses, setCourses] = useState([])
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        getCourses().then(console.log)
    }, [])

    const getCourses = async () => {
        const courses = []
        const { data } = await course.getAllCourses()
        data.forEach((d) => {
            const course = {
                name: d.courseName.replace(/_/g, ' ').toUpperCase(),
                description: d.courseDescription,
                imageSrc: '',
                imageAlt: d.courseName
            }
            courses.push(course)
        })

        courses.forEach((c) => {
            if (c.name === 'BOXE') {
                c.imageSrc = boxing
            } else if (c.name === 'REMISE EN FORME') {
                c.imageSrc = bodyRecomposition
            } else if (c.name === 'PERTE DE POIDS') {
                c.imageSrc = weightLoss
            } else if (c.name === 'PRISE DE MASSE') {
                c.imageSrc = weightGain
            }
        })

        setCourses(courses)
        setLoading(false)
    }

    if (loading) {
        return <Loading />
    }
    return (
        <div className="bg-gray-100">
            <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
                <div className="mx-auto max-w-2xl py-16 sm:py-24 lg:max-w-none lg:py-32">
                    <h2 className="text-2xl font-bold text-gray-900">Nos Services</h2>
                    <br />

                    <div className="mt-6 space-y-12 lg:grid lg:grid-cols-2 lg:gap-x-6 lg:space-y-0">
                        {courses.map((course) => (
                            <div key={course.name} className="group relative">
                                <div className="relative h-80 w-full overflow-hidden rounded-lg bg-white sm:aspect-h-1 sm:aspect-w-2 lg:aspect-h-1 lg:aspect-w-1 group-hover:opacity-75 sm:h-64">
                                    <img
                                        src={course.imageSrc}
                                        alt={course.imageAlt}
                                        className="h-full w-full object-cover object-center"
                                    />
                                </div>
                                <h3 className="mt-6 text-sm text-gray-500">
                                    <a href="#">
                                        <span className="absolute inset-0" />
                                        {course.name}
                                    </a>
                                </h3>
                                <p className="text-base font-semibold text-gray-900">
                                    <br />
                                    {course.description}
                                    <br />
                                    <br />
                                    <br />
                                </p>
                                <br />
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    )
}
