import React from 'react'

export default function NotFound() {
    return (
        <main className="grid min-h-full place-items-center bg-white px-6 py-24 sm:py-32 lg:px-8">
            <div className="text-center">
                <p className="text-base font-semibold text-brown">404</p>
                <h1 className="mt-4 text-3xl font-bold tracking-tight text-gray-900 sm:text-5xl">
                    Cette page n'existe pas
                </h1>
                <p className="mt-6 text-base leading-7 text-gray-600">
                    Désolés, la page que vous avez demandé n'existe pas.
                </p>
                <div className="mt-10 flex items-center justify-center gap-x-6">
                    <a
                        href="/public"
                        className="rounded-md bg-brown px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-beige hover:text-black focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-beige"
                    >
                        Retourner à l'accueil
                    </a>
                    <a href="mailto:wt.training075@gmail.com" className="text-sm font-semibold text-gray-900">
                        Contacter l'équipe support <span aria-hidden="true">&rarr;</span>
                    </a>
                </div>
            </div>
        </main>
    )
}
