import studio1 from '../../assets/studio1.jpg'
import studio2 from '../../assets/studio2.jpg'
import studio3 from '../../assets/studio3.jpg'
import studio4 from '../../assets/studio4.jpg'
import studio5 from '../../assets/studio5.jpg'
import studio6 from '../../assets/studio6.jpg'
import studio7 from '../../assets/studio7.jpg'

export default function Studio() {
    return (
        <div>
            <div className="relative overflow-hidden bg-white">
                <div className="pb-80 pt-16 sm:pb-40 sm:pt-24 lg:pb-48 lg:pt-40">
                    <div className="relative mx-auto max-w-7xl px-4 sm:static sm:px-6 lg:px-8">
                        <div className="sm:max-w-lg">
                            <h1 className="text-4xl font-bold tracking-tight text-gray-900 sm:text-6xl">
                                Mindset Studio
                            </h1>
                            <p className="mt-4 text-base text-gray-800">
                                Studio de coaching sportif privé situé dans le 17ème arrondissement de Paris au sein du
                                quartier Pereire.
                                <br />
                                Vous manquez de motivation, vous ne savez pas quels exercices faire pour atteindre vos
                                objectifs, vous en avez marre des salles de sport bondées ? Mindset Studio réponds à
                                toutes vos attentes !<br />
                                Un espace privé, équipé de machines, avec un coach diplômé prêt à vous aider à réaliser
                                vos objectifs.
                                <br />
                                Et oui, le terme est bien "aider" car tout objectif sera un travail d'équipe entre vous
                                et nous.
                                <br />
                                Faites nous confiance et lancez vous dans cette belle aventure sportive avec l'équipe
                                Mindset Studio.
                            </p>
                        </div>
                        <div>
                            <div className="mt-10">
                                {/* Decorative image grid */}
                                <div
                                    aria-hidden="true"
                                    className="pointer-events-none lg:absolute lg:inset-y-0 lg:mx-auto lg:w-full lg:max-w-7xl"
                                >
                                    <div className="absolute transform sm:left-1/2 sm:top-0 sm:translate-x-8 lg:left-1/2 lg:top-1/2 lg:-translate-y-1/2 lg:translate-x-8">
                                        <div className="flex items-center space-x-6 lg:space-x-8">
                                            <div className="grid flex-shrink-0 grid-cols-1 gap-y-6 lg:gap-y-8">
                                                <div className="h-64 w-44 overflow-hidden rounded-lg sm:opacity-0 lg:opacity-100">
                                                    <img
                                                        src={studio1}
                                                        alt=""
                                                        className="h-full w-full object-cover object-center"
                                                    />
                                                </div>
                                                <div className="h-64 w-44 overflow-hidden rounded-lg">
                                                    <img
                                                        src={studio2}
                                                        alt=""
                                                        className="h-full w-full object-cover object-center"
                                                    />
                                                </div>
                                            </div>
                                            <div className="grid flex-shrink-0 grid-cols-1 gap-y-6 lg:gap-y-8">
                                                <div className="h-64 w-44 overflow-hidden rounded-lg">
                                                    <img
                                                        src={studio7}
                                                        alt=""
                                                        className="h-full w-full object-cover object-center"
                                                    />
                                                </div>
                                                <div className="h-64 w-44 overflow-hidden rounded-lg">
                                                    <img
                                                        src={studio4}
                                                        alt=""
                                                        className="h-full w-full object-cover object-center"
                                                    />
                                                </div>
                                                <div className="h-64 w-44 overflow-hidden rounded-lg">
                                                    <img
                                                        src={studio5}
                                                        alt=""
                                                        className="h-full w-full object-cover object-center"
                                                    />
                                                </div>
                                            </div>
                                            <div className="grid flex-shrink-0 grid-cols-1 gap-y-6 lg:gap-y-8">
                                                <div className="h-64 w-44 overflow-hidden rounded-lg">
                                                    <img
                                                        src={studio6}
                                                        alt=""
                                                        className="h-full w-full object-cover object-center"
                                                    />
                                                </div>
                                                <div className="h-64 w-44 overflow-hidden rounded-lg">
                                                    <img
                                                        src={studio3}
                                                        alt=""
                                                        className="h-full w-full object-cover object-center"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
