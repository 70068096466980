import axios from 'axios'
const API_URL = process.env.REACT_APP_API_BASE_URL

const getRequest = async (url, config) => {
    return await axios.get(API_URL + url, config)
}

const postRequest = async (url, data, config) => {
    return await axios.post(API_URL + url, data, config)
}

const putRequest = async (url, data, config) => {
    return await axios.put(API_URL + url, data, config)
}

const deleteRequest = async (url, config) => {
    return await axios.delete(API_URL + url, config)
}

const request = {
    getRequest,
    postRequest,
    putRequest,
    deleteRequest
}

export default request
