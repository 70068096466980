import React from 'react'
import logo from '../../assets/logo.png'

export default function Footer() {
    return (
        <footer className="bg-beige rounded-lg shadow dark:bg-beige m-4">
            <div className="w-full max-w-screen-xl mx-auto p-4 md:py-8">
                <div className="sm:flex sm:items-center sm:justify-between">
                    <a href="https://www.instagram.com/mindset_studioparis/" className="flex items-center mb-4">
                        <img src={logo} className="h-8 mr-3" alt="Mindset Studio Logo" />
                        <span className="self-center text-2xl font-semibold whitespace-nowrap dark:text-black">
                            Mindset Studio
                        </span>
                    </a>
                    <ul className="flex flex-wrap items-center mb-6 text-sm font-medium text-black sm:mb-0 dark:text-black">
                        <li>
                            <a
                                href="https://www.instagram.com/mindset_studioparis/"
                                className="mr-4 hover:underline md:mr-6 "
                            >
                                Instagram
                            </a>
                        </li>
                        <li>
                            <a href="mailto:wt.training075@gmail.com" className="hover:underline">
                                Contactez-nous
                            </a>
                        </li>
                    </ul>
                </div>
                <hr className="my-6 border-brown sm:mx-auto dark:border-brown lg:my-8" />
                <span className="block text-sm text-black sm:text-center dark:text-black">
                    © 2023{' '}
                    <a href="https://www.instagram.com/mindset_studioparis/" className="hover:underline">
                        Mindset Studio
                    </a>
                    . Tous droits réservés.
                </span>
            </div>
        </footer>
    )
}
