import request from '../request'
import { getCookieToken } from '../../authentication/cookie'

const sendContactEmail = async (clientEmail, clientFirstName, clientLastName, clientPhoneNumber, emailContent) => {
    const body = {
        from: clientEmail,
        first_name: clientFirstName,
        last_name: clientLastName,
        phone_number: clientPhoneNumber,
        content: emailContent
    }

    return await request.postRequest('/emails/contact', body, {
        headers: {
            Authorization: `Bearer ${getHeaders()}`
        }
    })
}

const getHeaders = () => {
    return getCookieToken()
}

const email = {
    sendContactEmail
}

export default email
