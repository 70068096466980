import React from 'react'
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'
import Connection from '../components/connection/connection'
import Services from '../components/services/services'
import Contact from '../components/contact/contact'
import Profile from '../components/profile/profile'
import Studio from '../components/studio/studio'
import NotFound from '../components/not-found/not-found'

export const Routing = () => {
    return (
        <Router>
            <div>
                <Routes>
                    {/*<Route path="/connexion" element={<Connection />} />*/}
                    <Route path="/nos-services" element={<Services />} />
                    <Route path="/contact" element={<Contact />} />
                    {/*<Route path="/profil" element={<Profile />} />*/}
                    <Route path="/" element={<Studio />} />
                    <Route path="*" element={<NotFound />} />
                </Routes>
            </div>
        </Router>
    )
}
