import request from '../request'

const getAllCourses = async () => {
    return await request.getRequest('/courses', null)
}

const course = {
    getAllCourses
}

export default course
