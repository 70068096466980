import Cookies from 'universal-cookie'

export const setCookie = (token, id) => {
    const cookie = new Cookies()
    cookie.set('user', { token, id }, { path: '/', expires: new Date(Date.now() + 7200000) })
}

export const getCookieToken = () => {
    const cookie = new Cookies()
    return cookie.get('user')
}

export const removeCookie = () => {
    const cookie = new Cookies()
    cookie.remove('user')
}
