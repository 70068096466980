import './App.css'
import { Routing } from './routing/routing'
import Navbar from './components/navbar/navbar'
import Footer from './components/footer/footer'

function App() {
    return (
        <div className="App">
            <Navbar />
            <Routing />
            <Footer />
        </div>
    )
}

export default App
